/*
 * @Description: 
 * @Author: 高泽峰 Design.Gao
 * @Date: 2024-09-24 13:50:17
 * @LastEditTime: 2024-10-06 17:11:01
 * @LastEditors:  
 */
import http from "../utils/http.js";
import Base from "./Base.js";

export default class System extends Base {
  constructor(props) {
    super(props);
  } 
  static getAddressList(parm) {
    return http.post(`${Base.API}/address/list`,parm);
  }
 
}
