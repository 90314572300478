/*
 * @Author: 界点 5223308+界点@user.noreply.gitee.com
 * @Date: 2024-09-23 20:30:58
 * @LastEditors:  
 * @LastEditTime: 2024-11-12 13:24:27
 * @FilePath: \界点\src\api\Login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import http from "../utils/http";
import Base from "./Base";

export default class Login extends Base {
    constructor(props) {
        super(props);
    }

    static toLogin(parm) {
        // return new Promise((resovle, reject) => {//当接口没有的时候,模拟接口数据返回
        //     setTimeout(() => {
        //         let res = {
        //             code: 200,
        //             data: {
        //                 token: "gaozefeng123.。。。。。",
        //             },
        //         };
        //         resovle(res);
        //     }, 300);
        // });

        return http.post(`${Base.API}/auth/login`, parm);
    }


    //此处模拟返回用户信息表

    static userInfo() {
        return new Promise((resovle, reject) => {
            let res = {
                code: 200,
                data: {
                    userId: "123456",
                    userName: "mingkong",
                    nickName: "高泽峰",
                    role: "admin",
                },
            };
            resovle(res);
        });
    }




    static menuList() {
        return new Promise((resovle, reject) => { //模拟接口静态路由访问
            let res = [{
                    path: "/home",
                    name: "Home",
                    component: "",
                    meta: {
                        title: "统计首页",
                        icon: "DataLine",
                        role: ["admin"],
                        isShow: true,
                    },
                },
                {
                    path: "/consumer",
                    name: "Consumer",
                    component: "",
                    meta: {
                        title: "商户管理",
                        icon: "User",
                        role: ["admin"],
                        isShow: true,
                    },
                    children: [{
                            path: "consumerList",
                            name: "consumerList",
                            icon: "setting",
                            component: "@/views/consumer/merchant/index.vue",
                            meta: {
                                title: "商户列表",
                                icon: "User",
                                role: ["admin"],
                                isShow: true,
                            },
                        },
                        {
                            path: "staff",
                            name: "staff",
                            icon: "setting",
                            component: "@/views/consumer/staff/index.vue",
                            meta: {
                                title: "商户员工",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                            },
                        },


                    ]

                },
                {
                    path: "/user",
                    name: "User",
                    component: "",
                    meta: {
                        title: "会员管理",
                        icon: "User",
                        role: ["admin"],
                        isShow: true,
                    },
                    children: [{
                            path: "userList",
                            name: "userList",
                            icon: "setting",
                            component: "@/views/user/userList/index.vue",
                            meta: {
                                title: "商户列表",
                                icon: "User",
                                role: ["admin"],
                                isShow: true,
                            },
                        },
                        {
                            path: "pay",
                            name: "pay",
                            icon: "setting",
                            component: "@/views/user/pay/index.vue",
                            meta: {
                                title: "支付列表",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                            },
                        },


                    ]

                },
                // {
                //     path: "/team",
                //     name: "Team",
                //     component: "@/views/team/index.vue",
                //     meta: {
                //         title: "团队管理",
                //         icon: "User",
                //         role: ["admin"],
                //         isShow: true,
                //     },
                //     children: [{
                //             path: "teamList",
                //             name: "teamList",
                //             icon: "setting",
                //             component: "@/views/team/index.vue",
                //             meta: {
                //                 title: "团队成员列表",
                //                 icon: "User",
                //                 role: ["admin"],
                //                 isShow: true,
                //             },
                //         },


                //     ]

                // },

                // {
                //     path: "/points",
                //     name: "Points",
                //     component: "@/views/points/index.vue",
                //     meta: {
                //         title: "积分管理",
                //         icon: "User",
                //         role: ["admin"],
                //         isShow: true,
                //     },
                //     children: [{
                //             path: "pointsList",
                //             name: "pointsList",
                //             icon: "setting",
                //             component: "@/views/points/index.vue",
                //             meta: {
                //                 title: "积分明细",
                //                 icon: "User",
                //                 role: ["admin"],
                //                 isShow: true,
                //             },
                //         },


                //     ]

                // },

                // {
                //     path: "/rights",
                //     name: "Rights",
                //     component: "@/views/rights/index.vue",
                //     meta: {
                //         title: "权益管理",
                //         icon: "User",
                //         role: ["admin"],
                //         isShow: true,
                //     },
                //     children: [{
                //             path: "rightsList",
                //             name: "rightsList",
                //             icon: "setting",
                //             component: "@/views/rights/index.vue",
                //             meta: {
                //                 title: "权益列表",
                //                 icon: "User",
                //                 role: ["admin"],
                //                 isShow: true,
                //             },
                //         },


                //     ]

                // },





                // {
                //     path: "/level",
                //     name: "Level",
                //     component: "@/views/level/index.vue",
                //     meta: {
                //         title: "级别管理",
                //         icon: "Tickets",
                //         role: ["admin"],
                //         isShow: true,
                //     },
                // },
                {
                    path: "/goods",
                    name: "Goods",
                    component: "",
                    icon: "Present",
                    meta: {
                        title: "商品管理",
                        icon: "Present",
                        role: ["admin"],
                        isShow: true,
                    },
                    children: [{
                            path: "product",
                            name: "Product",
                            icon: "Present",
                            component: "@/views/goods/product/index.vue",
                            meta: {
                                title: "商品列表",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                            },

                        },
                        {
                            path: "product/update",
                            name: "Update",
                            icon: "Present",
                            component: "@/views/goods/product/update.vue",
                            meta: {
                                title: "编辑商品",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: false,
                            },
                        },

                        {
                            path: "category",
                            name: "category",
                            icon: "Operation",
                            component: "@/views/goods/category/index.vue",
                            meta: {
                                title: "商品分类管理",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                            },
                        },


                    ]

                },
                {
                    path: "/order",
                    name: "Order",
                    component: "",
                    meta: {
                        title: "订单管理",
                        icon: "Tickets",
                        role: ["admin"],
                        isShow: true,
                    },
                    children: [{
                            path: "orderList",
                            name: "OrderList",
                            icon: "Present",
                            component: "@/views/order/orderList.vue",
                            meta: {
                                title: "订单列表",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: true,
                            },

                        },
                        {
                            path: "orderDetails",
                            name: "orderDetails",
                            icon: "Present",
                            component: "@/views/order/orderDetails.vue",
                            meta: {
                                title: "订单详情",
                                icon: "Tickets",
                                role: ["admin"],
                                isShow: false,
                            },


                        }




                    ]
                }
            ];
            resovle({ code: 200, data: res });
        });
    }
}