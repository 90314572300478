<template>
  <div class="tableBox">
    <!-- 表格操作按钮 -->
    <section class="Handle" v-if="isHandle">
      <el-row type="flex" justify="left">
        <el-col :span="24">
          <el-button
            v-for="(item, index) in tableHandles"
            :key="index"
            :icon="item.icon"
            :type="item.type"
            @click="item.handle()"
            :size="item.size"
            >{{ item.label }}</el-button
          >
        </el-col>
     
      </el-row>
    </section>
    <section class="Handle" v-if="isHandleFrom"> 
          <el-form :model="formData" ref="form" label-width="auto" label-position="right">
            <div class="from">
              <el-form-item
              v-for="(item, index) in tableHandlesFrom"
              :key="index"
              :label="item.label"
              :prop="item.prop"
            >
              <el-input
                v-if="item.type === 'input'"
                v-model="formData[item.prop]"
                :placeholder="`请输入${item.label}`"
              ></el-input>
              <el-select
                v-else-if="item.type === 'select'"
                class="select"
                v-model="formData[item.prop]"
                :placeholder="`请选择${item.label}`"
              >
                <el-option
                  v-for="(option, index) in item.options"
                  :key="index"
                  :label="option.label"
                  :value="option.value"
                ></el-option>
              </el-select>
             
              <!-- 其他类型的输入组件可以继续添加 -->
            </el-form-item>
            <el-button type="primary" @click="onSubmit">确定</el-button>
            </div>
            
          </el-form>
     
 
    </section>
 
    <!-- 数据表格 -->
    <section class="tables">
      <div class="tablepos">
 
      </div>
      <el-table
        ref="multipleTable"
        height="100%"
        :data="tableData"
        :size="size"
        :border="isBorder"
        v-loading="loading"
        empty-text="暂无数据"
        @selection-change="handleSelectionChange">
        :defaultSelections="defaultSelections"
      >
        <!-- 是否显示表格复选框 -->
        <el-table-column
          v-if="isSelection"
          type="selection"
          align="center"
          width="50"
        ></el-table-column>
        <!-- 是否需要索引列 -->
        <el-table-column
          v-if="isIndex"
          type="index"
          :label="indexLabel"
          align="center"
          width="50"
        ></el-table-column> 
        <!-- 数据栏 -->
        <el-table-column
          v-for="item in tableCols"
          :key="item.id"
          :label="item.label"
          :prop="item.prop"
          :width="item.width"
          :align="item.align"
        >
           <template #default="scope">
            <!-- 按钮 -->
            <span v-if="item.type === 'Button'">
              <el-button
                v-for="(btn, index) in item.btnList"
                :disabled="btn.isDisabled && btn.isDisabled(scope.row)"
                :type="btn.type"
                :size="btn.size || size"
                :icon="btn.icon"
                :key="index"
                @click="btn.handle(scope.row)"
                >{{ btn.label }}</el-button
              >
            </span> 
            <span v-if="item.type === 'image'">
              <el-image style="width: 50px; height: 50px" :src="[scope.row[item.prop]]"  />
 
            </span> 
            <span v-else-if="item.type === 'statusButton'">
              <el-button  
                :type="item.btntype[scope.row[item.prop]]"
                :size="item.size || size"
                :icon="item.icon" 
                @click="item.handle(scope.row)"
                >{{item.btnText[scope.row[item.prop]]}}</el-button
              >
            </span> 
            <!-- 默认 -->
            <span v-if="!item.type">{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </section>
 
    <!-- 分页 -->
    <section class="ces-pagination" v-if="isPagination">
      <el-pagination
        style="
          display: flex;
          justify-content: center;
          height: 100%;
          align-items: center;
        "
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total,sizes ,prev, pager, next,jumper"
        :page-size="pagination.pageSize"
        :current-page="pagination.pageNum"
        :total="pagination.total"
      ></el-pagination>
    </section>
  </div>
</template>
 
<script>
export default {
  name: "SearchTable",
  data() {
    return {
      formData: {},
    
    };
  },
  props: {
    //表格型号:mini,medium,small
    size: {
      type: String,
      default: "medium",
    },
    //是否显示表格索引
    isIndex: {
      type: Boolean,
      default: false,
    },
    //indexLabel:表格索引列名称
    indexLabel: {
      type: String,
      default: "序列",
    },
    //是否显示表格复选框
    isSelection: {
      type: Boolean,
      default: false,
    },
    //isHandle控制表格操作按钮显示
    isHandle: {
      type: Boolean,
      default: false,
    }, 
    //tableHandles按钮配置对象，用于循环遍历展示
    tableHandles: {
      type: Array,
      default: () => [],
    },
    isHandleFrom: {
      type: Boolean,
      default: false,
    },
    tableHandlesFrom: {
      type: Array,
      default: () => [],
    }, 
    multipleSelection: {
      type: Array,
      default: () => [],
    },
    //isBorder
    isBorder: {
      type: Boolean,
      default: true,
    },
    //加载效果
    loading: {
      type: Boolean,
      default: false,
    },
    // 表格数据
    tableData: { type: Array, default: () => [] },
    // 表格列配置
    tableCols: { type: Array, default: () => [] },
    // 默认选中列
    defaultSelections: { type: [Array, Object], default: () => null },
    // 是否显示分页
    isPagination: { type: Boolean, default: true },
    // 分页数据
    pagination: {
      type: Object,
      default: () => ({ pageSize: 10, pageNum: 1, total: 0 }),
    },
    
  },
  methods: {
    handleCurrentChange(val) { 
      console.log(val)
      this.pagination.pageNum=val
      this.$emit("refresh",this.pagination);
    },
    handleSizeChange() { 
      this.pagination.pageSize=val
      this.$emit("refresh",this.pagination);
    },

    handleSelectionChange(val) { 
      
      this.$emit("setMultipleSelection",val);
 
    },
    toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },

      onSubmit(){
        this.$emit("search",this.formData);
 
      }
    
  },
};
</script>
 
<style lang="less" scoped>
 .tableBox{ 
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;

 }
.Handle{ 
  margin-top: 10px;
  margin-bottom: 10px;
}
.tables{
  overflow: auto;
  position: relative;
  flex: 1;
  border: solid 1px rgb(235, 235, 235);
  .tablepos{ 
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; 
  }
  
}
.ces-table-require::before {
  content: "*";
  color: red;
}
.el-pagination {
  float: right;
  margin-right: 10px;
  margin-top: 5px;
}
.from{ 
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  .el-form-item{
    margin-right: 20px;
  }
  .select{
    width: 150px;
  }
}
</style>