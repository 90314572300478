<template>
  <el-form :model="formData" ref="form" label-width="120px">
    <el-form-item
      v-for="(item, index) in formItems"
      :key="index"
      :label="item.label"
      :prop="item.prop"
    >
      <el-input
        v-if="item.type === 'input'"
        v-model="formData[item.prop]"
        :placeholder="`请输入${item.label}`"
      ></el-input>
      <el-select
        v-else-if="item.type === 'select'"
        v-model="formData[item.prop]"
        :placeholder="`请选择${item.label}`"
      >
        <el-option
          v-for="(option, index) in item.options"
          :key="index"
          :label="option.label"
          :value="option.value"
        ></el-option>
      </el-select>
      <!-- 其他类型的输入组件可以继续添加 -->
    </el-form-item>
  </el-form>
</template>
 
<script>
export default {
  data() {
    return {
      formData: {},
      formItems: [
        { label: '用户名', prop: 'username', type: 'input' },
        {
          label: '性别',
          prop: 'gender',
          type: 'select',
          options: [{ label: '男', value: 'male' }, { label: '女', value: 'female' }],
        },
        // 可以根据需要添加更多的字段
      ],
    };
  },
  methods: {
    resetForm() {
      this.$refs.form.resetFields();
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(this.formData);
          } else {
            reject(new Error('表单验证失败'));
          }
        });
      });
    },
  },
};
</script>