/*
 * @Description: 
 * @Author: 高泽峰 Design.Gao
 * @Date: 2024-09-24 13:20:40
 * @LastEditTime: 2024-11-13 15:02:49
 * @LastEditors:  
 */

let api = {
  pre: {
    BASE_API: 'api'||  import.meta.env.VITE_APP_BASE_API,
  },
  prd: {
    BASE_API:   import.meta.env.VITE_APP_BASE_API||'/api/v1',
   // BASE_API:   import.meta.env.VITE_APP_BASE_API||'http://admin.test.jmipro.com/api/v1',
  },
 
}; 
let meta= import.meta.env
console.log(meta)
const env =meta.MODE==='dev'? "pre":'prd'; 
console.log(env)
export default api[env];
