/*
 * @Description: 
 * @Author: 高泽峰 Design.Gao
 * @Date: 2024-09-24 13:20:40
 * @LastEditTime: 2024-11-10 22:10:15
 * @LastEditors:  
 */
import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersist from "pinia-plugin-persist";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus';

import App from "./App.vue";
import router from "./router";
//加载element-ui框架
import installElementPlus from "@/plugins/element";
import "./assets/main.css";
//轻量原子类样式库
import "saduocss"; 
import SearchTable from "./components/SearchTable/index.vue";//注册一个全局的动态表格组件
import SearchForm  from './components/SearchForm/index.vue'
 
const app = createApp(App);
import Editor from "@tinymce/tinymce-vue";
// store持久化，缓存到session中
const store = createPinia();
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.use(key, component)
}
store.use(piniaPluginPersist);
app.use(store);
app.use(router);
app.use(installElementPlus);
app.use(ElMessage);

app.use(SearchTable);
app.use(SearchForm);
app.use(Editor);
app.mount("#app");
