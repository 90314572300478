/*
 * @Description: 
 * @Author: 高泽峰 Design.Gao
 * @Date: 2024-09-24 13:20:40
 * @LastEditTime: 2024-11-12 14:23:57
 * @LastEditors:  
 */
import API from "../config/index.js";
import http from "../utils/http.js";
 
export default class Base {
  static API = API.BASE_API;
  static VERSION = API.BASE_VERSION;
  version() {
    return "v.1.0";
  }
  //返回所有的城市列表
  static addressList() {

  }

  //上传
  // let formData = new FormData();
  // formData.append('file', file);
  // formData.append('fileName', file.name);
  static uploadurl = (data) => {
    return `${Base.API}/upload/upload`
  };

  static upload = (data) => {
    return http({
      url: `${Base.API}/upload/upload`,
      method: "post",
      headers: {
        "Content-type": "multipart/form-data",
      },
      data: data,
    });
  };
}
